import {
  STAKER_STORAGE_SIZE,
  decodeStakerData,
  StakerStorage,
} from "./state/staker.account";
import { PublicKey } from "@solana/web3.js";

const accountKeyLists: any = [
  "Avqjkrq9D3pEViTcFuTZ6XvK9HfRKA9kxvEkmMJSRqFP",
  "odJWWUiyNBuBXwx4N4uKNpXp6N3gmyZtVZoAC4sdzEu",
  "BiE3citepqrYwf72gXEbocZ8vLvnBg5AGHVJ7H1z7GLV",
  "7Z3M8V8Szac3X6v6NtDpPxZ7jspHB7yy6LehsmVcLqGC",
  "ETXyTzSyT3HT2pcSe3yJd8sKyv1j3ZY1vxi2nav5QMUF",
  "Fs4RUVEMm7joWGEysUdxLY3Jeyv9FBD8rp2RqMT1qCDT",
  "8y2Do9MtvNHaJwPJyGuBPKb2a9XMA9pj1JvVENmiGxk",
  "2gQ2bzgL6kzXCaHJkK6sHgWuriBb2i7pW5Y6saCPu3tu",
  "3oVrat4yMzusrLPX7nqJn7vMdhshjun1CqZWzirms6Fq",
  "FWbY63GMFbKGVvw7hiWdmYKmKrLyGrhFEfEoDaDGwPPe",
  "ELTfSxoUc6CCUhYwKr43tDsoRtfJBGzTTJt4r1rPRrhn",
  "12ezCUpuu1SHHLWkGSnmG7iMRFzfv54d33zspZDPZVXK",
  "2ouPFyUvHVTGRutj7dGY2PreAC5oNRAhxkhUeDLvkfuk",
  "HBsPFWJHAMKSmHjANnYnBDv6V22ZjRqq1YsUGaS3eouE",
  "B8SrmYXd2fMg9cCfks26RCQMMSHfXWM8aXhxEQM1oe9V",
  "6AHaHKPumC5ZLNfRF2kdcw1duWEuYe4E599N34BjUtX2",
  "5bYpHYBnVqQHoQWSKrzFQe9J4atL2aYSdnEzZNS9UNRf",
  "8HpQrg1gSuWQCmRGg8j9JZ7PYqFbVaJQu1PEva94eMbH",
  "3LcqoNKALHqV1fNWWjW4muutyfBdy6nZtjkMgxuEyBbN",
  "AkeZa2mVqguWEATir4SfrPVGhrKZADSgbnUQxE7dEeG4",
  "MD15gfs98tAeRdzB3o1mDv6DvEnCQ1SvZsr4eLhiioc",
  "DSfB3nf6hyMNCQrcqC8AGKsKbow7s8ayWqC4NXoGj772",
  "JCWcrk6g8Tnrzc5aKwb59UQdsHVVGrRiAyC4xwBj8SjG",
  "3P3UfC2Ep898PmRULPzVrWifqpq9sui1KACwRZ8F4sRk",
  "AjPkh8E9gR1ARrCzU64Sd9kAQAntr6d4VQBfaen6tkVT",
  "98HsgS1dphMfASbCBVqE2MnCfEvW7Vy6wVvfzTkFCCvC",
  "4LByXLsAd7Ek21ruwJSMb16zScUkHbCieLf5k5eLQ6c9",
  "DnTewCq3YCACsnUg2pEacqPPjoV26XFqgZMypESefxUL",
  "C6cCfqv1g92fWsSZZvahvbGoo3EkMWKTVzvhSr1epwNX",
  "814QX5UJQQVArCgBTmGZSUChHesEJvfNxEcLFJ9F57NG",
  "Baub4PvyBmRznJoMzWggh13mH9GWAuhSpvuLBFxpHRVg",
  "2NzKyezUGMRPKerRSNSqTEuBqb8xjmMCxQjoWxQAXSU5",
  "6yDiPDzGXdVw2hF8F43kUMwMErgWq9o1dvejYwi3JjEL",
  "DmwgARDoEg2jz77nEG1tmfNsMT8Ffx4KMeTAUPrpMunv",
  "G2bZbgYa3X4KKSkwHAyBsAAqV8x4XbpvhDKAkrW2ALZY",
  "8GCwusf8amFBwP1rxxtK9SgJ1UdfJQAe59HRWdYhSoNc",
  "23vRnvcpeD8FXRZfEX9jPWah9qY1tMbGX17zVVk1T396",
  "5bNpZ2oBQEu46Lt7cbLheM7eXDNRkRGEz1KWf9PiaEKY",
  "2DMkmDLqv3CrTCdt4PJrVU3UuVoXeXDHD3qhv2CCiumW",
  "Bxtx9PQeAMME9qK6qZ1fhKSBD7X75sRtoA5d6fwfa1J3",
  "CjfYMPfLY8jJbMPeg6m1vbL2EnSEtEMwna1No7JNXdTn",
  "AggTJnuYuRkwf1sPX7KYbXGWMgh6Gijs8SHJaVBZC7bQ",
  "Z7vbQUu2MYhCfhZw8bcK3x2HsrbnTjHvHWv2W35ff14",
  "81Vrmt9iVufq49ZAHPKnVeJ2kh83WqTRy7tvM4iFipqj",
  "4JEpRqzXK1GDN2JGqVkCdZx8dHwvZp2JxvyHzeGsXfvz",
  "69SSN2VPgY5noQ5dA2CtBKDzoQKDCCwyZ78XJJZM3jwX",
  "RyTbqiEaauwXdkF9XggYGmvKfuhmRaxP6vQPP5ikbL4",
  "4fZoA3Vr2AUDGnGK4N9GPrgx2yy2p77askhBnS2uTMHL",
  "GgrC6Fm2XwJWfB5Rm18SyU6b8SRkLsYYXv4Cddh9coLi",
  "ARo8mJSR27ybe3n6a7C8ZWckzqnL8pUbadggmrGP7mje",
  "BZSPAi6oE5NKDi9Gc6eTKqPGFzRjEznrJR6ZTeKZhAC8",
  "DThBzfyHf3i9sKrMomkRD2UjnmbYHZjW1EfrNDXcKJWj",
  "2Lbxk3eSg4QkAGZtXjuCiJ8v1fmBFcP9cSnoHVnQ7EvD",
  "6vtYzFTueeyEvaNHVMNfsdvKN3MwQRbuGa1tVSa26aKb",
];
function sortItems(array: any): any {
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array.length; j++) {
      if (!array[j] || !array[j + 1]) {
        continue;
      }

      if (array[j].points < array[j + 1].points) {
        let temp = array[j];
        array[j] = array[j + 1];
        array[j + 1] = temp;
      } else if (
        array[j].points === array[j + 1].points &&
        array[j].stake_date.getTime() > array[j + 1].stake_date.getTime()
      ) {
        let temp = array[j];
        array[j] = array[j + 1];
        array[j + 1] = temp;
      }
    }
  }
  return array;
}

const getStats = async (connection: any) => {
  const stakerStorageKeys = await accountKeyLists.map(function (
    publickey: any
  ) {
    return new PublicKey(publickey);
  });

  // const stakerStorageAccounts = await connection.getProgramAccounts(new PublicKey(process.env.REACT_APP_PROGRAM_ID || ''), {
  //     filters: [{ dataSize: STAKER_STORAGE_SIZE }],
  // });

  // const stakerStorageKeys = stakerStorageAccounts.map(
  //     function (account: any) {
  //         console.log('account', account.pubkey.toBase58());
  //         return account.pubkey;
  //     }
  // );

  let totalStaked = 0;
  let filterStakerInfos: any[] = [];

  const stakedNFTMetaList = await Promise.all(
    stakerStorageKeys.map((stakerStorageKey: PublicKey) => {
      return getStakerNft(connection, stakerStorageKey);
    })
  );
  for (let i = 0; i < stakerStorageKeys.length; i++) {
    // ;        const item = stakerStorageKeys[i];

    const stakerData = stakedNFTMetaList[i];
    if (stakerData) {
      const stakeNFTRecord = getStakeNftStat(stakerData);
      totalStaked = totalStaked + parseInt(stakeNFTRecord.no_nfts);
      filterStakerInfos.push(stakeNFTRecord);
    }
  }

  filterStakerInfos = filterStakerInfos.slice(0, 50);

  return {
    totalStaked,
    userList: sortItems(filterStakerInfos),
  };
};

const getStakerNft = async (connection: any, stakerStorageKey: PublicKey) => {
  const storage = await connection.getAccountInfo(stakerStorageKey);
  if (storage === null) {
    console.log("Error: cannot find storage account");
    return null;
  }

  const data = decodeStakerData(storage.data);
  return data;
};

const getStakeNftStat = (stakerData: StakerStorage): any => {
  const stakes = stakerData.stakes;

  let stakeDate = new Date();
  stakes.forEach((item) => {
    const _stakeDate = new Date(item.date_initialized.toNumber());
    if (stakeDate.getTime() > _stakeDate.getTime()) {
      stakeDate = _stakeDate;
    }
  });
  return {
    staker_address: stakerData.staker_address,
    stake_date: stakeDate,
    no_nfts: stakerData.stakes.length.toString(),
    points: stakerData.totalPoints(),
  };
};

export default getStats;
