const burnList = [
  "6mEQrz1GknX7E43Ynsep5VMSp1ESZpCYoCXCCHABjs3C",
  "GwpqAge9XTmNYgqvUnJiMxikdETo3jLeR6Be343a9ALU",
  "4yy4FjiQBL6dJTyJqvgsHj2fmLAfGWF7MWzGjUDZHUjy",
  "4Urbw9Z3GWkmvTLedpWf4KLYLsEwxbMQXEao8EL5fkHn",
  "FGK7rBrCx8ieb2rhXZQXJeTa6ejmMJZyGd5jpnfZqbLB",
  "5ZPymzTnhF8iDGRgT4rnN35VH6RZkQeTvzZbVeR1dXuC",
  "73uLiPGimB65TW5ESCvBAfFDd8td193A5Y9WQAotcQYo",
  "35aQqxAeRMNHbJmFuHqr3WGs5Uahk5maPYcXnJ2PeydQ",
  "HRmusPf6iHjj9hSRAswYtxb1jsTZUu3NGMLCcPzwUfeC",
  "DHdZdyPtGNWSLt5FoCWpqz4qV4Bpep2AdE23CXQZa473",
  "4pSuYm9DnMfJFmp69pKV4K98TcHdzz7ydS1VUq5gjihw",
  "3zM2e9w52zBRZ7kU73m8vbPFT23pQYowLrkXiYAff9be",
  "HwDLgKGWcPeXYQU7B6iyYYtspjzeSRaCvD51az4h2g1q",
  "2ALMyrHatPEDsZj9EuYkAFdMWrEWay71En8TKLi6AvDV",
  "FcMbJYFsHhwKEDQRkU9ujaYygfKsSUoPBpdC3TPHR54E",
  "6h793N1gH6ct99ZX1ZfMhBUY1oZk5M6ygXBQxcqWrXCC",
  "2kbUMygc6GbKWNg17Rgyxgh5twitXa4YE1xHtzA4MEmm",
  "4Je7ggTqpFXSeTKM7E2rCVPR2A8aP3hrXhYupqEH195T",
  "7mHwMNJWtpA7aU1GUKz4MZxucgcuP5ebrxEUgrxiWZpo",
  "HVDYb8KcwpqYMrcBdmWpghjAnviRaAxXchwe7tKntKuW",
  "9313QqjEPCEdMfVfGSXmgJCJdYeuxNKqAYqYEKptqk9s",
  "9Mm5DuVLsCMXwfvn6RopFHB3sF51ZUaALwn7hSWtsKgY",
  "d9Sgfy4dgZag8GZuUhuK2GSJqNpXyY8ZpHvXDwH7GCP",
  "CxGwwYJ4nPgfmuRv4W84jnHZqkh7VGS9uBcp4qVdNL9W",
  "HsGwYQxHPHj26WUcFq6KpshYVkLaExJ8dQZP6xB1HnAT",
  "DrVpxTU7UysnTtSwFke24FEo9We5k3CtopUjZJ24U2Wa",
  "99kP8AZckgZFi6nzpDMG9GKWRTTzGig5BpLSPubWrUas",
  "HcqCNod4NtskXvDp58J3Hdkn83nxxBVYiehwCeBYADEW",
  "8UiYBiKpRdsniwaNygjZ7dnTbBQA1dTg9qcE5KhLAv6",
  "47x1xtQ4ScBSJZm4NBKagZtzWLfP8RDUh6E9PucAsAur",
  "6oW51ZP1eezcdEPDRbE8v9bX2fPCLbmPXzBFooLXXrXQ",
  "Bzh4qzvTi2ZaTG7uGmqoUCTqPcVc2AdRX8qz6TCUjn7T",
  "AhhrLXnwxnR5YftH318a82eoZbLAFNmv1K147tfMNyvX",
  "ENeU31cGb3bs5vaQKF6ZVASa8n3u2LQiB5JWttRi9giU",
  "6As789MBDbcswWwJszM81Sa1Gvno6kFqsYrKpYmxY7GQ",
  "HXvqP6KvhHMGkFNwdcxCuDswjERmAop48ZUeG9jiZsBN",
  "GPyFavyXU6T5TW8Nd9zHf4WQbBNnKVqZPQzCCxKkuC7w",
  "2cEXfAjHhUjTjnUvc96Qqg2FcJrsSSTpjDnA11r2fLA8",
  "CTa2LoQqMTykoUQ7UAFLviQy8cHvKRKiHcwnZ9f8TM2P",
  "GHD4VLLUccWJtBbnhgaPZvwVDgofUQoqjpL4jn5C3Ed7",
  "FeqNb29FEWvLoxVJ6FL46EpL8VD8xMrwCjPfchzLK85V",
  "BjFkNww32KyZ94u2Vbg4uraAZEarGAvAwHSnhoE3dyDp",
  "DXXQ4RwJc354xWv6recaHVf68QcZDYV5KdgEqZibiyyz",
  "8iDYVwSyawdEiH9AcG6HTZnG1upEtk4af1QfDDaCdgjH",
  "GgwmWq7biByoedCZo16Xx4oRC5k4vE9EWg8WycuaATyY",
  "DKtvVKXzECnufNdL3VjVvb5L7yAgoHFijsRuzNEfJhpp",
  "2TzkJbxFXEzEXxDKjZ1ZN7LMf5KnzA7q3YniR8RUSers",
  "J1xNQfLU1DsU6h3PdDJRGT5xoRPsTYjEpb6PFCgWZVdQ",
  "CkJd6vsmeWXYvDFbSw5C4X4EycahRjhqYqVMCQQ7EVgk",
  "GWxgPytAbrhMM5RwJ2v3YUrjubyBQvBpPWjrbqNMm8Wc",
  "862LP5qvCzwQQiYwLciTbT6xC3TgcCSbEJXvsj7vhqbJ",
  "34i5MJKN6enjdhzt5TT1z5Xkffm4R1EW8q1gAseL6fiD",
  "4ZAyPPFHRBL36GbnwicUHtXwBYTKQJ3Wfedfg9RqXwbY",
  "4AHp7yHbky3mY2FYCEPk4pKrso2Cn2vhyJHf7xDUQWnE",
  "9szHBXayrabAFtdzjEp8LgX8PGdgfQmT1MvGZDAuy9eh",
  "24JmvibwMkRsvNqpAaNBNjjGzqvNaX3nUA5ycRW9Ebe7",
  "4BjNB7JMJ2ZExdt8czLxoSTPZyDs8QUNMkKG2Ygc5zLe",
  "2a7rq4Mkhbgw2DzDyqvr5awVc9pdW6gq4YF3TT4VgYYn",
  "FiZ9ziCYnYfYkLaJPv3KqyKnpsfQLeWRDQop5sJrtmjs",
  "22JfXVCVgknB1mcWb3i6Dh7q9ymUxyucURNguthNcYLm",
  "4rji6qapWUauxxsiPucHsTJnw9fE8EZqnALR89igJxXa",
  "Cy2qMtc4hwoESVk7XagkysSGp7whNBYxYyDfwJkxsohp",
  "8iUCuU9r1j3oUajxdK6hnrw9uaN8tmt74sF7XZSob4B6",
  "CzGouk6F5KeCqT1YMUti5gBwcjdmYzFXkno1kimqhpPw",
  "GHBUY1G7GcKUxsZ1wtjVu4YtJVVT1hkfZrqUArbywzaX",
  "Gpz533TgdiRB8MJcmHZVRtMFQcQMpX33H3ujCebTpZxx",
  "7z2TiSnGvzLpgiLXS4rJdxqcGWK36VNkWe3qApdxTVSg",
  "7c7qLyDktTEpBiPbx7Y5k8V847MJh8YG5fVgbABQGGmB",
  "Ev8CtK1ZW5LNkKY9WEGAfcETynCGua2Jeh2JVtbyhTwR",
  "BK4xcNJyFbdBuSnpJGnmmFokyFTp9KJP6yQh4okkydtA",
];

export default burnList;
